import { createStore } from "vuex";
import db from "../firebase";

const store = createStore({
  state: {
    showModalLoginRegister: false,
    user: {},
    localSuperAdmin: {},
    listLocals: {},
    isAuthenticated: false,
    isManualNavigation: false,
    orderLocal: null,
    categorySelected: "",
    orderProducts: [],
    productSelected: {},
    businessProfileSelected: {},
    adminLocalInfo: {},
    additionalSelected: {},
    insumoSelected: {},
    subRecipeSelected: {},
    sizeSelected: {},
    customerOrder: "",
    historicTurnObj: {},
    orderDetails: "",
    businessApi: "",
    businessLocalId: 0,
    loading: true,
    filter: "all",
    database: db,
    configurationsAdminWeb: {
      takeout: true,
      reservation: true,
      delivery: true,
      type_delivery_default: "2",
      auto_print_invoices: false,
      auto_print_orders: false,
      auto_print_software: false,
    },
    webVersion: 0,
    documentTypeForPrint: 0,
    meassureUnits: [
      {
        name: "Gramos",
        value: "gr",
      },
      {
        name: "Kilogramos",
        value: "kg",
      },
      {
        name: "Mililitros",
        value: "ml",
      },
      {
        name: "Litros",
        value: "l",
      },
      {
        name: "Galones",
        value: "gal",
      },
      {
        name: "Onzas",
        value: "oz",
      },
      {
        name: "Unidad",
        value: "un",
      },
    ],
    paginationOptionsAgGrid: [
      15,
      100,
      500,
      1000,
      100000
    ],
    firstPaginationSize: 15,
    list_access_permissions: [
      {
        value: "1",
        name: "Gestionar órdenes en locales",
      },
      {
        value: "2",
        name: "Ventas históricas por local",
      },
      {
        value: "3",
        name: "Gestión de la carta",
      },
      {
        value: "4",
        name: "Reporte de ventas",
      },
      {
        value: "5",
        name: "Reporte de productos vendidos",
      },
      {
        value: "6",
        name: "Gestión y reportes de motorizados",
      },
      {
        value: "7",
        name: "Gestión y reportes de Cupones",
      },

      {
        value: "8",
        name: "Reporte de usuarios",
      },

      {
        value: "9",
        name: "Generar pedidos",
      },
      // {
      //   value: "10",
      //   name: "Gestionar horarios de atención",
      // }, // deprecated
      {
        value: "11",
        name: "Configuración de locales",
      },
      {
        value: "12",
        name: "Enviar notificaciones push",
      },
      {
        value: "13",
        name: "Gestionar reclamos de clientes",
      },
      {
        value: "14",
        name: "Gestionar CV's de aspirantes",
      },
      {
        value: "15",
        name: "Asignar puntos",
      },
      {
        value: "16",
        name: "Campañas de descuento en productos",
      },
      {
        value: "17",
        name: "Gestión de cajas",
      },
      {
        value: "18",
        name: "Gestionar Recetas",
      },
      {
        value: "19",
        name: "Inventario y almacenes",
      },
      {
        value: "20",
        name: "Reporte Sunat",
      },
      {
        value: "21",
        name: "Gestión de mesas",
      },
      {
        value: "22",
        name: "Pantalla de cocina",
      },
    ],
    reg_id: '',
    platform: 'web_admin'
  },
  mutations: {
    setOrderProductMenos(state, index) {
      //resta en 1 el SelectCantidad de id del product
      //state.orderProducts = state.orderProducts.map(product => { if (product.id == data.id) { product.SelectCantidad = product.SelectCantidad - 1 } return product })

      state.orderProducts[index].SelectCantidad =
        state.orderProducts[index].SelectCantidad - 1;
      localStorage.setItem(
        "orderProducts",
        JSON.stringify(state.orderProducts)
      );
    },
    setOrderProductMas(state, index) {
      //aumenta en 1 el SelectCantidad de id del product
      //state.orderProducts = state.orderProducts.map(product => { if (product.id == data.id) { product.SelectCantidad = product.SelectCantidad + 1 } return product })
      state.orderProducts[index].SelectCantidad =
        state.orderProducts[index].SelectCantidad + 1;

      localStorage.setItem(
        "orderProducts",
        JSON.stringify(state.orderProducts)
      );
    },
    setUser(state, data) {
      state.user = data;
      localStorage.setItem("user", JSON.stringify(data));
    },
    setBusinessProfileSelected(state, data) {
      state.businessProfileSelected = data;
      localStorage.setItem("businessProfileSelected", JSON.stringify(data));
    },
    setAdminLocalInfo(state, data) {
      state.adminLocalInfo = data;
      localStorage.setItem("adminLocalInfo", JSON.stringify(data));
    },
    setCategorySelected(state, data) {
      state.categorySelected = data;
      localStorage.setItem("categorySelected", JSON.stringify(data));
    },
    setProductSelected(state, data) {
      state.productSelected = data;
      localStorage.setItem("productSelected", JSON.stringify(data));
    },
    setSizeSelected(state, data) {
      state.sizeSelected = data;
      localStorage.setItem("sizeSelected", JSON.stringify(data));
    },
    setAdditionalSelected(state, data) {
      state.additionalSelected = data;
      localStorage.setItem("additionalSelected", JSON.stringify(data));
    },
    setInsumoSelected(state, data) {
      state.insumoSelected = data;
      localStorage.setItem("insumoSelected", JSON.stringify(data));
    },
    setSubRecipeSelected(state, data) {
      state.subRecipeSelected = data;
      localStorage.setItem("subRecipeSelected", JSON.stringify(data));
    },
    setOrderLocal(state, data) {
      state.orderLocal = data;
      localStorage.setItem("orderLocal", JSON.stringify(data));
    },
    pushOrderProduct(state, data) {
      state.orderProducts.push(data);
      localStorage.setItem(
        "orderProducts",
        JSON.stringify(state.orderProducts)
      );
    },
    setAuthenticatedTrue(state) {
      state.isAuthenticated = true;
    },
    setAuthenticatedFalse(state) {
      state.isAuthenticated = false;
    },
    setIsManualNavigation(state) {
      state.isManualNavigation = true;
    },
    setIsNotManualNavigation(state) {
      state.isManualNavigation = false;
    },
    updateOrderProduct(state, data) {
      const index = state.orderProducts.findIndex(item => item.id === data.id);
      if (index !== -1) {
        state.orderProducts[index] = data;
        localStorage.setItem(
            "orderProducts",
            JSON.stringify(state.orderProducts)
        );
      }
    },
    deleteItemOfOrderProducts(state, id) {
      const index = state.orderProducts.findIndex(item => item.id === id);
      if (index !== -1) {
        state.orderProducts.splice(index, 1);
        localStorage.setItem(
            "orderProducts",
            JSON.stringify(state.orderProducts)
        );
      }
    },
    clearProducts(state) {
      state.orderProducts = [];
      localStorage.removeItem("orderProducts");
    },
    setShowModalLoginRegister(state, value) {
      state.showModalLoginRegister = value;
    },
    setCustomerOrder(state, data) {
      state.customerOrder = data;
      localStorage.setItem("customerOrder", JSON.stringify(data));
    },
    setHistoricTurnObj(state, data) {
      state.historicTurnObj = data;
      localStorage.setItem("historicTurnObj", JSON.stringify(data));
    },
    setOrderDetails(state, data) {
      state.orderDetails = data;
      localStorage.setItem("orderDetails", JSON.stringify(data));
    },
    setBusinessApi(state, data) {
      state.businessApi = data;
      localStorage.setItem("businessApi", JSON.stringify(data));
    },
    setBusinessLocalId(state, data) {
      state.businessLocalId = data;
      localStorage.setItem("businessLocalId", JSON.stringify(data));
    },
    setLocalSuperAdmin(state, data) {
      state.localSuperAdmin = data;
      localStorage.setItem("localSuperAdmin", JSON.stringify(data));
    },
    setListLocals(state, data) {
      state.listLocals = data;
      localStorage.setItem("listLocals", JSON.stringify(data));
    },
    setConfigurationsAdminWeb(state, data) {
      state.configurationsAdminWeb = data;
      localStorage.setItem("configurationsAdminWeb", JSON.stringify(data));
    },
    setWebVersion(state, data) {
      state.webVersion = data;
      localStorage.setItem("webVersion", JSON.stringify(data));
    },
    setDocumentTypeForPrint(state, data) {
      state.documentTypeForPrint = data;
      localStorage.setItem("documentTypeForPrint", JSON.stringify(data));
    },
    setReg_id(state, data) {
      state.reg_id = data;
      localStorage.setItem("reg_id", JSON.stringify(data));
    },
  },
});

export default store;
